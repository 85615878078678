import { useOpenClosed } from "../../hooks/openClosedManager/useOpenClosed";
import { useNotification } from "../../hooks/notification/useNotification";
import styled from "styled-components";
import { colors } from "../../uitils/colors";
import { OpenLogo } from "./OpenLogo";
import { ClosedLogo } from "./ClosedLogo";
import { UnreadMessageCount } from "./UnreadMessageCount";
import bubbleAnimation from "./bubble-animation.json";
import Lottie from "lottie-react";
import { useRef } from "react";

const BubbleContainer = styled.div`
  position: absolute;
  cursor: pointer;
  top: 10px;
  left: 10px;
  height: 60px;
  width: 60px;
  max-height: 60px;
  background-color: ${colors.blue};
  border-radius: 100%;
  box-shadow: 0 2px 4px rgba(81, 169, 225, 0.14), 
              0 4px 5px rgba(81, 169, 225, 0.12), 
              0 1px 10px rgba(81, 169, 225, 0.2);
  
`;


export const SimpleBubble = () => {
  const firstRender = useRef<boolean>(false);
  const { isOpen, setIsOpen } = useOpenClosed();
  const { messages } = useNotification();
  let animationClassName = 'animate__animated ';
  if (messages.length > 0) {
    firstRender.current = true;
    animationClassName += 'animate__fadeIn';
  } else if (!firstRender.current) {
    animationClassName += 'notDisplay';
  } else {
    animationClassName += 'animate__fadeOut';
  }

  return <>
    <Lottie className={animationClassName} animationData={bubbleAnimation} />
    <BubbleContainer className="animate__animated animate__fadeIn"  onClick={() => {
      setIsOpen(!isOpen);
    }}>
      <OpenLogo isOpen={isOpen} />
      <ClosedLogo isOpen={isOpen} />
      <UnreadMessageCount messageCount={isOpen ? 0 : messages.length} />
    </BubbleContainer>
  </>;
}
