import styled from "styled-components";
import AgentAvatar from "../../../assets/agentAvatar.png";

const Container = styled.div`
  min-width: 36px;
  min-height: 36px;
  border-radius: 100%;
  background-image: url(${AgentAvatar});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`;

export const HeaderAgentIcon = () => {
  return <Container />
}
