import styled from "styled-components";
import React from 'react'
import { colors } from "../../uitils/colors";
import { ReactComponent as CloseButtonLogo } from "../../assets/closeButtonLogo.svg";
import { Content } from "./Content";

const Container = styled.div`
  background-color: ${colors.white};
  border-radius: 8px;
`;

const ContentContainer = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  padding: 0 30px 20px 30px;
  color: ${colors.black};
`;

const WhoContainer = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 30px;
  padding-left: 30px;
  padding-top: 20px;
  padding-bottom: 3px;
  color: ${colors.grey};
`;

const ExitButtonContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 35px;
  padding: 7px;
  margin: 7px;
  cursor: pointer;
  stroke: ${colors.lightGrey};
  &:hover {
    stroke: ${colors.grey};
  }
  transition: 0.3s;
`;

type Props = {
  who: string
  content: string;
  onClose: (e: React.MouseEvent<HTMLDivElement>) => void;
}
export const MessageContainer = ({
  who, content, onClose,
}: Props) => {
  return <Container>
    <ExitButtonContainer onClick={onClose}>
      <CloseButtonLogo />
    </ExitButtonContainer>
    <WhoContainer>{who}</WhoContainer>
    <ContentContainer>
      <Content content={content} />
    </ContentContainer>
  </Container>;
}