import { useEffect, useState } from "react";
import { ReactComponent as MicrophoneLogo } from "../../../../assets/microphone.svg";
import styled from "styled-components";
import { colors } from "../../../../uitils/colors";
import { useVoiceRecorder } from "../../../../hooks/voiceRecognition/useVoiceRecorder";
import { recognition } from "../../../../hooks/voiceRecognition/recognition";


type MicrophoneLogoContainerProps = {
  isRecognizing: boolean;
}

const MicrophoneLogoContainer = styled.div<MicrophoneLogoContainerProps>`
  margin-top: 3px;
  
  cursor: pointer;
  
  &.active {
    fill: ${colors.red};
  }
  &.active:hover {
    fill: ${colors.darkRed};
  }
  &.not-active {
    fill: ${colors.blue};
  }
  &.not-active:hover {
    fill: ${colors.darkBlue};
  }
  
  transition: 0.3s;
  animation-duration: ${props => props.isRecognizing ? '1s' : '3s'};
`;

type Props = {
  currentText: string;
  setCurrentText: (arg0: string) => void;
}


export const MicrophoneButton = ({ currentText, setCurrentText }: Props) => {
  const {
    supported, stop, start, state
  } = useVoiceRecorder();
  const [isActive, setIsActive] = useState(false);
  const [isRecognizing, setIsRecognizing] = useState(false);
  useEffect(() => {
    if (isActive) {
      start().then(() => console.log('started'));
    } else {
      if (state !== 'recording') {
        return;
      }
      stop().then((blobs) => {
        setIsRecognizing(true);
        recognition(new Blob(blobs)).then((text) => {
          let newText = currentText ? currentText + ' ' + text : text;
          setCurrentText(newText);
          setIsRecognizing(false);
        });
      });
    }
  }, // eslint-disable-next-line
    [isActive]);

  let className = 'animate__animated ';
  if (isRecognizing) {
    className += 'animate__headShake animate__infinite '
  } else if (state === 'recording') {
    className += 'animate__flash animate__infinite ';
  }

  return <MicrophoneLogoContainer
    isRecognizing={isRecognizing}
    style={supported ? {} : { display: 'none' }}
    onClick={() => !isRecognizing && setIsActive(!isActive)}
    className={className + (state === 'recording' ? 'active' : 'not-active')}
  >
    <MicrophoneLogo />
  </MicrophoneLogoContainer>
}