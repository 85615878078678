import { JSX } from "react";
import { Message } from "../../../../hooks/chatting/backend/types";
import styled from "styled-components";
import { AgentIcon } from "./AgentIcon";
import { colors } from "../../../../uitils/colors";
import { roleMap } from "../../../../uitils/roleConversation";
import { Time } from "./Time";
import { useFeedback } from "../../../../hooks/feedback/useFeedback";

type Props = {
  message: Message;
}

const Container = styled.div`
  width: 100%;
  
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 30px;
  padding-right: 17px;
`;

const MessageContainer = styled.div`
  border-radius: 8px;
  margin-right: 10px;
  background-color: ${colors.backgroundGrey};
  display: flex;
  flex-direction: column;
`;

const MessageRoleContainer = styled.div`
  padding-left: 25px;
  padding-top: 21px;
  padding-bottom: 5px;
  
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 25px;
  
  color: ${colors.grey}
`

const MessageContentContainer = styled.div`
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 24px;
  
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  
  color: ${colors.black};
`;

const MesageContentBlockContainer = styled.div`
  word-break: break-word;
  min-height: 8px;
`;

const Link = styled.a`
  text-decoration: none;
`

const LeaveAFeedbackBtn = styled.a`
  text-decoration: none;
`;

const TimeContainer = styled.div`
  padding-right: 25px;
`

function formatSimpleLinksInText(t: string, next: (text: string) => JSX.Element) {
  const pattern = /(https?:\/\/\S+[^\s.])/;
  const splitted = t.split(pattern);
  return <span>
    {splitted.map((p, i) => {
      if (i % 2 === 0) {
        return <span key={i}>{next(p)}</span>;
      } else {
        return <Link target="_blank" href={p}>{p}</Link>;
      }
    })}
  </span>
}

function formatSimpleMarkdownLinksInText(t: string, next: (text: string) => JSX.Element) {
  const pattern = /\[(https?:\/\/\S+[^\s.])]/;
  const splitted = t.split(pattern);
  return <span>
    {splitted.map((p, i) => {
      if (i % 2 === 0) {
        return <span key={i}>{next(p)}</span>;
      } else {
        return <Link key={i} target="_blank" href={p}>{p}</Link>;
      }
    })}
  </span>
}

function formatMarkdownV2LinksInText(t: string, next: (text: string) => JSX.Element) {
  const pattern = /\[([^\]]*): ([^)]*)]/;
  const splitted = t.split(pattern);

  let buffer = []
  for (let i = 0; i < splitted.length; i++) {
    if (i % 3 === 0) {
      buffer.push(<span key={i}>{next(splitted[i])}</span>);
    }
    if (i % 3 === 2) {
      buffer.push(<Link key={i} target="_blank" href={splitted[i]}>{splitted[i - 1]}</Link> )
    }
  }

  return <span>
    {buffer}
  </span>
}


function formatMarkdownLinksInText(t: string, next: (text: string) => JSX.Element) {
  const pattern = /\[([^\]]*)]\(([^)]*)\)/;
  const splitted = t.split(pattern);

  let buffer = []
  for (let i = 0; i < splitted.length; i++) {
    if (i % 3 === 0) {
      buffer.push(<span key={i}>{next(splitted[i])}</span>);
    }
    if (i % 3 === 2) {
      buffer.push(<Link key={i} target="_blank" href={splitted[i]}>{splitted[i - 1]}</Link> )
    }
  }

  return <span>
    {buffer}
  </span>
}

type MonadElement = (text: string, next: (text: string) => JSX.Element) => JSX.Element;
function monad(sequence: MonadElement[]): (text: string) => JSX.Element {
  if (sequence.length === 0) {
    return (text: string) => <>{text}</>;
  }
  const [el, ...rest] = sequence;

  const next = (text: string) => {
    return monad(rest)(text);
  }

  return (text: string) => el(text, next);
}

const MONAD_PARSING_SEQUENCE: MonadElement[] = [
  formatMarkdownLinksInText,
  formatMarkdownV2LinksInText,
  formatSimpleMarkdownLinksInText,
  formatSimpleLinksInText, // put any new parser before this one
]


const formatLinksInText = monad(MONAD_PARSING_SEQUENCE);


export const AssistantMessage = ({ message }: Props) => {
  const date = new Date(message.createdAt);
  const { setIsFeedbackActive, isFeedbackActive, isSent } = useFeedback();

  let className = 'animate__animated ';
  if (message.state === 'unseen') {
    className += 'animate__slideInUp';
  }

  let reviewBtn = null
  if (message.isEndOfDialog && !isSent) {
    reviewBtn = <LeaveAFeedbackBtn href="#" onClick={() => setIsFeedbackActive(!isFeedbackActive)}>
      Please, leave a feedback!
    </LeaveAFeedbackBtn>
  } else if (message.isEndOfDialog && isSent) {
    reviewBtn = <LeaveAFeedbackBtn href="#">
      Thank You for Your feedback!
    </LeaveAFeedbackBtn>
  }

  return <Container className={className}>
    <IconContainer>
      <AgentIcon />
    </IconContainer>
    <MessageContainer>
      <MessageRoleContainer>{roleMap[message.chatRole]}</MessageRoleContainer>
      <MessageContentContainer>
        {
          message.chatContent
            .split('\n')
            .map((t, i) => <MesageContentBlockContainer key={i}>
              {formatLinksInText(t)}
            </MesageContentBlockContainer>)
        }{ reviewBtn }
      </MessageContentContainer>
    </MessageContainer>
    <TimeContainer>
      <Time time={date} />
    </TimeContainer>
  </Container>
}
