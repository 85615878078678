import { useEffect, useRef, useState } from "react";




export const useVoiceRecorder = () => {
  const chunksRef = useRef<Blob[]>([]);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [supported, setSupported] = useState(false);
  const [state, setState] =
    useState<'recording' | 'stopped'>('stopped')
  useEffect(() => {
    if (!!navigator.mediaDevices.getUserMedia) {
      setSupported(true);
    }
    return () => {
      if (mediaRecorderRef.current && mediaRecorderRef.current?.state !== 'inactive') {
        mediaRecorderRef.current?.stop()
        mediaRecorderRef.current?.stream.getTracks().forEach(t => t.stop());
      }
    }
  }, []);

  async function initMediaRecorder() {
    const constraints = { audio: true };
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    const mediaRecorder = new MediaRecorder(stream)
    mediaRecorderRef.current = mediaRecorder;

    mediaRecorder.ondataavailable = (e) => {
      chunksRef.current.push(e.data);
    }
    return mediaRecorder;
  }

  return {
    supported,
    state,
    start: async () => {
      if (!supported || state === 'recording') {
        return;
      }
      let recorder = await initMediaRecorder();
      await new Promise<void>(resolve => {
        recorder.onstart = () => resolve();
        recorder.start();
      });
      setState('recording');
    },
    stop: async () => {
      let mediaRecorder = mediaRecorderRef.current as MediaRecorder;
      if (!mediaRecorder || !supported || state === 'stopped') {
        return;
      }
      await new Promise<void>(resolve => {
        mediaRecorder.onstop = (e) => {
          mediaRecorderRef.current?.stream.getTracks().forEach(t => t.stop());
          resolve();
        }
        console.log(mediaRecorder.state)
        mediaRecorder.stop();
      })
      setState('stopped');

      const recorderChunks = chunksRef.current;
      chunksRef.current = [];
      return recorderChunks;
    }
  }
}