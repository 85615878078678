export class SafeLocalStorage {
  getItem(key: string): string | null {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      return null;
    }
  }

  setItem(key: string, value: string) {
    try {
      localStorage.setItem(key, value);
    } catch (e) {}
  }

  clear() {
    try {
      localStorage.clear();
    } catch (e) {}
  }
}

export const safeLocalStorage = new SafeLocalStorage();
