import { useEffect, useRef, useState } from "react";
import { Message } from "./Message";
import { useChat } from "../../../hooks/chatting/useChat";
import styled from "styled-components";
import backgroundSvg from "../../../assets/background.svg";
import { colors } from "../../../uitils/colors";
import { pad } from "../../../uitils/time";
import { WaitingIndicator } from "./WaitingIndicator";

type MessageContainerProps = {
  isInitialized: boolean;
}

const MessageContainer = styled.div<MessageContainerProps>`
  height: 100%;
  overflow-y: auto;
  overflow-x: clip;
  background-image: url(${backgroundSvg});
  background-position: top;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-behavior: ${props => props.isInitialized ? 'smooth' : 'unset'};
  
  &::-webkit-scrollbar {
    width: 6px;
    background-color: ${colors.scrollWhite};
    border-left: 1px solid ${colors.backgroundGrey};
  }
  /* Track */
  &::-webkit-scrollbar-track {
    
  }
   
  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: ${colors.scrollGrey}; 
    transition: 3s;
    &:hover {
      background-color: ${colors.lightGrey};
    }
  }
`;

const StartDateContainer = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 27px;
  padding-top: 14px;
  padding-bottom: 15px;
  
  color: ${colors.grey};
`;

export const Messages = () => {
  const {
    messages
  } = useChat();
  const [isInitialized, setIsInitialized] = useState(false);
  const anchorRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (messages.length > 0 && !isInitialized) {  // first scroll should be not smooth
      setIsInitialized(true);
    }
    anchorRef.current?.scrollIntoView();

  }, [isInitialized, messages.length]);

  let firstMessageDate = null;
  if (messages.length > 0) {
    const date = new Date(messages[0].createdAt);
    firstMessageDate = <StartDateContainer>
      {pad(date.getDate())}.{pad(date.getMonth())}.{date.getFullYear()}
    </StartDateContainer>
  }

  return <MessageContainer isInitialized={isInitialized}>
    { firstMessageDate }
    { messages.map((m, i) => <Message key={i} message={m} />) }
    <WaitingIndicator
      isActive={messages.length === 0 || messages[messages.length - 1].chatRole === 'user'}
    />
    <div ref={anchorRef} />
  </MessageContainer>
}
