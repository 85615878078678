import { JSX, useEffect, useState } from 'react';
import { chatContext } from "./chatContext";
import AbstractChattingBackend from "./backend/abstractChattingBackend";
import { Message } from "./backend/types";

type Props = {
  children: JSX.Element;
  chatBackend: AbstractChattingBackend;
}
export const ChatProvider = ({ chatBackend, children }: Props): JSX.Element => {
  const [messages, setMessages] = useState<Message[]>([])
  const [chatSessionUid, setChatSessionUid] = useState('');
  useEffect(() => {
    chatBackend.start();
    return () => {
      chatBackend.stop();
    }
  }, [chatBackend]);

  chatBackend.onMessages((newMessages) => {
    setMessages(newMessages);
  });
  chatBackend.onChatSessionUid((uid) => {
    setChatSessionUid(uid);
  })

  return <chatContext.Provider value={{
    chatSessionUid,
    messages,
    publishMessage: (text) => {
      chatBackend.publishUserMessage(text);
    },
    updateMessagesState: (state) => {
      chatBackend.updateMessagesState(state);
    },
  }}>
    { children }
  </chatContext.Provider>
}
