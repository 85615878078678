import { useFeedback } from "../../../hooks/feedback/useFeedback";
import styled from "styled-components";
import { colors } from "../../../uitils/colors";
import { ReactComponent as FilledStar } from "../../../assets/filledStar.svg";
import { ReactComponent as UnfilledStar } from "../../../assets/star.svg";
import { ReactComponent as OpenLogoImg } from "../../../assets/openLogo.svg";

const Container = styled.div`
  width: 100%;
  z-index: -1;
  transition: min-height 500ms, max-height 500ms, border-top-width 500ms;
  border-top: 1px solid ${colors.backgroundGrey};
  overflow: clip;
  display: flex;
  flex-direction: column;
  &.show {
    min-height: 45px;
    max-height: 45px;
  }
  &.remove {
    min-height: 0;
    max-height: 0;
  }
`;

const StarsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const StarContainer = styled.div`
  fill: ${colors.blue};
  cursor: pointer;
`

const ExitContainer = styled.div`
  margin-top: 5px;
  margin-right: 5px;
  align-self: flex-end;
  stroke: ${colors.black};
  max-width: 12px;
  max-height: 12px;
  cursor: pointer;
`;

type StarProps = {
  index: number;
  setStars: (arg0: number) => void;
  stars: number;
};
const Star = ({ index, setStars, stars }: StarProps) => {
  let starIcon;
  if (index > stars) {
    starIcon = <UnfilledStar />;
  } else {
    starIcon = <FilledStar />;
  }

  return <StarContainer onClick={() => {
    if (index !== stars) {
      setStars(index);
    } else {
      setStars(index - 1);

    }
  }}>
    {starIcon}
  </StarContainer>
}

export const FeedbackStars = () => {
  const { isFeedbackActive, stars, setStars, setIsFeedbackActive } = useFeedback()
  let className = '';
  if (isFeedbackActive) {
    className += 'show';
  } else {
    className += 'remove';
  }

  return <Container className={className}>
    <ExitContainer onClick={() => setIsFeedbackActive(false)}>
      <OpenLogoImg width="12" height="12" />
    </ExitContainer>
    <StarsContainer>
      {
        [1, 2, 3, 4, 5].map(
          (i) => <Star key={i} index={i} setStars={setStars} stars={stars} />
        )
      }
    </StarsContainer>
  </Container>
}
