export const colors = {
  blue: '#51A9E1',
  lightBlue: '#77BCE8',
  darkBlue: '#2b96da',
  darkBrandBlue: '#103654',
  orange: '#FF9C00',
  white: '#FFFFFF',
  scrollWhite: '#F3F3F3',
  scrollGrey: '#D3D3D3',
  black: '#121212',
  lightGrey: '#BCBCBC',
  grey: '#757575',
  backgroundGrey: '#E9ECEF',
  green: '#6CE883',
  transparentWhite: 'rgba(255, 255, 255, 0.7)',
  red: '#FF0000',
  darkRed: '#CC0000',
};
