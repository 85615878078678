import { JSX, useRef, useState } from 'react'
import { feedbackContext } from "./feedbackContext";
import { useChat } from "../chatting/useChat";
import { safeLocalStorage } from "../../uitils/safeLocalStorage";

type Props = {
  children: JSX.Element;
  url: string;
}

export const FeedbackProvider = ({ children, url }: Props) => {
  const { chatSessionUid } = useChat();
  const chatSessionUidRef = useRef('');
  chatSessionUidRef.current = chatSessionUid;
  const [isFeedbackActive, setIsFeedbackActive] = useState(false);
  const [stars, setStars] = useState(0);
  const starsRef = useRef(0);
  starsRef.current = stars;
  const [isSent, setIsSent] = useState(
    JSON.parse(safeLocalStorage.getItem('isSent') || 'false')
  );
  return <feedbackContext.Provider value={{
    isFeedbackActive, setIsFeedbackActive,
    stars, setStars,
    publishFeedback: (content) => {
      fetch(`${url}/feedback`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          stars: starsRef.current,
          content,
          chatSessionUid: chatSessionUidRef.current,
        })
      }).then(() => {});
      setIsFeedbackActive(false);
      setStars(0);
      setIsSent(true);
      safeLocalStorage.setItem('isSent', 'true');
    },
    isSent,
  }}>
    {children}
  </feedbackContext.Provider>
}
