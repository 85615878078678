import styled from "styled-components";
import { colors } from "../../../../uitils/colors";
import { pad } from "../../../../uitils/time";

const TimeContainer = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 27px;
  color: ${colors.lightGrey};
`;

type Props = {
  time: Date
}
export const Time = ({ time }: Props) => {
  return <TimeContainer>{time.getHours()}:{pad(time.getMinutes())}</TimeContainer>;
}
