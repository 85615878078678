import { createContext } from 'react';


export type OpenClosedContext = {
  isOpen: boolean;
  setIsOpen: (arg0: boolean) => void;
  wasEverOpen: boolean;
}
export const openClosedContext = createContext<OpenClosedContext>({
  isOpen: false,
  setIsOpen: arg0 => {},
  wasEverOpen: false,
});
