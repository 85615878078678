import './App.css';
import { IframeMessagingProvider } from "./hooks/iframeMessaging/IframeMessagingProvider";
import { OpenClosedProvider } from "./hooks/openClosedManager/OpenClosedProvider";
import { env } from "./hooks/utils/env";
import { NotificationProvider } from "./hooks/notification/NotificationProvider";
import { Routes } from './components/Routes';


function App() {
  return <IframeMessagingProvider>
    <OpenClosedProvider ttlSeconds={env.ttl}>
      <NotificationProvider>
        <Routes />
      </NotificationProvider>
    </OpenClosedProvider>
  </IframeMessagingProvider>
}

export default App;
