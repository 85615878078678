import { BubbleImageContainer } from "./BubbleImageContainer";
import { ReactComponent as OpenLogoImage } from "../../assets/openLogo.svg";
import styled from "styled-components";
import { colors } from "../../uitils/colors";

const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 24px;
  height: 24px;
  stroke: ${colors.white};
`;

type Props = {
  isOpen: boolean;
}
export const OpenLogo = ({ isOpen }: Props) => {
  return <BubbleImageContainer show={isOpen}>
    <Container>
      <OpenLogoImage />
    </Container>
  </BubbleImageContainer>
}
