import { createContext } from 'react';
import { Message, MessageState } from "./backend/types";


export type ChatContext = {
  messages: Message[];
  publishMessage: (text: string) => void;
  updateMessagesState: (newState: MessageState) => void;
  chatSessionUid: string;
}
export const chatContext = createContext<ChatContext>({
  chatSessionUid: '',
  messages: [],
  publishMessage: (text) => {},
  updateMessagesState: (newState) => {}
});
