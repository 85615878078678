import { BrowserRouter, Route, Routes as RoutesInternal } from "react-router-dom";
import { Conversation } from "./Conversation";
import { Bubble } from "./Bubble";
import { Notification } from "./Notification";
import { useIframeMessaging } from "../hooks/iframeMessaging/useIframeMessaging";
import { useEffect } from "react";
import { safeLocalStorage } from "../uitils/safeLocalStorage";

export const Routes = () => {
  const { onMessage, publishMessage } = useIframeMessaging();
  useEffect(() => {
    onMessage('routes', 'resetChat', () => {
      safeLocalStorage.clear();
      publishMessage('chatIsReset', {});
    });
  }, [publishMessage, onMessage]);

  return <BrowserRouter>
    <RoutesInternal>
      <Route path="/" element={<Conversation />}/>
      <Route path="/bubble" element={<Bubble />}/>
      <Route path="/notification" element={<Notification />}/>
    </RoutesInternal>
  </BrowserRouter>
}
