import { ChatProvider } from "../../hooks/chatting/ChatProvider";
import HttpChatBackend from "../../hooks/chatting/backend/httpChatBackend";
import LocalStorageWithTTLCache from "../../hooks/chatting/backend/cache/localStorageWithTTLCache";
import RamCache from "../../hooks/chatting/backend/cache/ramCache";
import { Chat } from "./Chat";
import { env } from "../../hooks/utils/env";
import { FeedbackProvider } from "../../hooks/feedback/FeedbackProvider";
import { WindowScaleProvider } from "../../hooks/windowScale/WindowScaleProvider";

const isLocalStorageAvailable = () => {
  try {
    localStorage.getItem('anything');
    return true;
  } catch (e) {
    return false;
  }
}

export const Conversation = () => {
  return (
    <ChatProvider
      chatBackend={new HttpChatBackend(
        isLocalStorageAvailable() ? new LocalStorageWithTTLCache(env.ttl) : new RamCache(),
        env.url
      )}
    >
      <FeedbackProvider url={env.url}>
        <WindowScaleProvider>
          <Chat />
        </WindowScaleProvider>
      </FeedbackProvider>
    </ChatProvider>
  );
}