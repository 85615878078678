import { JSX, useEffect, useState } from "react";
import { notificationContext } from "./notificationContext";
import { useIframeMessaging } from "../iframeMessaging/useIframeMessaging";
import { Message } from "../chatting/backend/types";

type Props = {
  children: JSX.Element;
}

export const NotificationProvider = ({ children }: Props) => {
    const {
    onMessage, removeHandler, publishMessage
  } = useIframeMessaging();
  const [messages, setMessages] = useState<Message[]>([])

  useEffect(() => {
    onMessage('notificationProvider', 'newNotification', (messages: Message[]) => {
      setMessages(messages);
    });

    return () => {
      removeHandler('notificationProvider', 'newNotification');
    }
  }, [onMessage, removeHandler])

  return <notificationContext.Provider value={{
    messages,
    setMessages: (msgs: Message[]) => {
      publishMessage('newNotification', msgs);
    }
  }}>
    { children }
  </notificationContext.Provider>
}