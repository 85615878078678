import { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../uitils/colors";
import { WaitingPhrases } from "./WaitingPhrases";

type ContainerProps = {
  isVisible: boolean;
  isActive: boolean;
}
const Container = styled.div<ContainerProps>`
  --animate-delay: 1s;
  --animate-duration: 1s;
  justify-self: flex-end;
  margin-top: auto;
  
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 32px;
  visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
  display: ${props => props.isActive ? 'flex' : 'None'};
  transition: 0.5s;
`;

const IndicatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 26px;
  margin-bottom: 16px;
  justify-content: space-between;
  
  width: 40px;
`;


const IndicatorBottomEl = styled.div`
  --animate-delay: 0.5s;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  
  background-color: ${colors.lightGrey};
  animation: upDown 2s infinite ease-in-out;
  animation-delay: 0.5s;
  @keyframes upDown {
    0%, 100% { transform: translatey(0px); }
    50% { transform: translatey(8px); }
  }
`

const IndicatorTopEl = styled.div`
  border-radius: 100%;
  width: 8px;
  height: 8px;
  
  background-color: ${colors.lightGrey};
  
  animation: upDown 2s infinite ease-in-out;
`

type Props = {
  isActive: boolean;
}
export const WaitingIndicator = ({ isActive }: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    setIsVisible(true);
  }, [isActive]);

  let className = 'animate__animated ';
  if (isActive) {
    className += 'animate__slideInLeft';
  } else {
    className += 'animate__slideOutDown';
  }

  return <Container isVisible={isVisible} isActive={isActive} className={className}>
    <IndicatorContainer>
      <IndicatorBottomEl />
      <IndicatorTopEl />
      <IndicatorBottomEl />
    </IndicatorContainer>
    <WaitingPhrases isActive={isActive} />
  </Container>
}
