import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { sleep } from "../../../uitils/time";
import { colors } from "../../../uitils/colors";

type Props = {
  isActive: boolean;
}

const phrases = [
  'Let me think...',
  'Hmm...',
  'I need a little bit more time...',
  'Let me see...',
];

const Container = styled.div`
  margin-left: 8px;
  padding-bottom: 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: ${colors.grey};
  height: 27px;
`;

export const WaitingPhrases = ({ isActive }: Props) => {
  const [phraseToShow, setPhraseToShow] = useState('');
  const [phraseToShowBuff, setPhraseToShowBuff] = useState('');
  const isActiveRef = useRef(isActive);
  useEffect(() => {
    isActiveRef.current = isActive;

    const runScenario = async () => {
      await sleep(5000);
      if (!isActiveRef.current) { return; }
      let index = 0;
      while (true) {
        if (index === phrases.length) {
          index = 0;
        }
        setPhraseToShowBuff(phrases[index]);
        setPhraseToShow(phrases[index]);
        index += 1;
        await sleep(5000);
        if (!isActiveRef.current) { setPhraseToShow(''); setPhraseToShowBuff(''); return; }
        setPhraseToShow('');
        await sleep(1000);
        if (!isActiveRef.current) { setPhraseToShowBuff(''); return; }
      }
    }
    if (isActive) {
      runScenario().then(() => {});
    }
  }, [isActive])

  let className = 'animate__animated ';
  if (!isActive || !phraseToShow) {
    className += 'animate__fadeOut';
  } else {
    className += 'animate__fadeIn';
  }


  return <Container className={className}>
    { phraseToShowBuff }
  </Container>
}
