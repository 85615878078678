import { ReactComponent as IncreaseLogo } from "../../../assets/increaseWindow.svg";
import { ReactComponent as DecreaseLogo } from "../../../assets/decreaseWindow.svg";
import styled from "styled-components";
import { colors } from "../../../uitils/colors";
import { useWindowScale } from "../../../hooks/windowScale/useWindowScale";

const Container = styled.div`
  fill: ${colors.white};
  display: flex;
  justify-self: flex-end;
  flex-direction: row;
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

const DecreaseButtonContainer = styled.div`
  --animate-duration: 0.5s;
`;

const IncreaseButtonContainer = styled.div`
  --animate-duration: 0.5s;
  position: relative;
  left: -24px;
`;

export const WindowSizeButton = () => {
  const { isResized, setIsResized } = useWindowScale();

  let classNameIn = 'animate__animated animate__rotateIn';
  let classNameOut = 'animate__animated animate__rotateOut';

  return <Container>
    <DecreaseButtonContainer
      className={isResized ? classNameIn : classNameOut}
      onClick={() => setIsResized(!isResized)}
    >
      <DecreaseLogo />
    </DecreaseButtonContainer>
    <IncreaseButtonContainer
      className={isResized ? classNameOut : classNameIn}
      onClick={() => setIsResized(!isResized)}
    >
      <IncreaseLogo />
    </IncreaseButtonContainer>
  </Container>
}
