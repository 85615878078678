import { createContext } from "react";

type WindowScaleContext = {
  isResized: boolean;
  resizingStarted: boolean;
  resizingFinished: boolean;
  setIsResized: (arg0: boolean) => void;
};

export const windowScaleContext = createContext<WindowScaleContext>({
  isResized: false,
  resizingStarted: false,
  resizingFinished: false,
  setIsResized: (arg0: boolean) => {},
});
