import styled from "styled-components";
import AgentAvatar from '../../../../assets/agentAvatar.png';

const Container = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-image: url(${AgentAvatar});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`;

export const AgentIcon = () => {
  return <Container />
}
