import { BubbleImageContainer } from "./BubbleImageContainer";
import { ReactComponent as ClosedLogoImage } from "../../assets/closedLogo.svg";
import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 40px;
  height: 40px;
`;

type Props = {
  isOpen: boolean;
}
export const ClosedLogo = ({ isOpen }: Props) => {
  return <BubbleImageContainer show={!isOpen} >
    <Container>
      <ClosedLogoImage />
    </Container>
  </BubbleImageContainer>
}
