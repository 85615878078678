import { createContext } from "react";

export type IframeMessagingContext = {
  onMessage: (id: string, type: string, callback: (data: any) => void) => void;
  publishMessage: (type: string, data: any) => void;
  removeHandler: (id: string, type: string) => void;
}

export const iframeMessagingContext = createContext<IframeMessagingContext>({
  onMessage: (id, type, callback) => {},
  publishMessage: (type, data) =>{},
  removeHandler: (id, type) => {},
})