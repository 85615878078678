import styled from "styled-components";
import { HeaderAgentIcon } from "./HeaderAgentIcon";
import { colors } from "../../../uitils/colors";
import { WindowSizeButton } from "./WindowSizeButton";

const HeaderContainer = styled.div`
  min-height: 70px;
  max-width: calc(100% - 70);
  
  border-radius: 8px 8px 0 0;
  background: linear-gradient(
    89.95deg, ${colors.blue} 0.03%, 
    ${colors.lightBlue} 72.89%, 
    ${colors.blue} 99.95%
  );
  /* 8px | 10% */

  box-shadow: 0 8px 10px rgba(81, 169, 225, 0.04), 
              0 3px 14px rgba(81, 169, 225, 0.02), 
              0 4px 5px rgba(81, 169, 225, 0.1);
  
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 20px;
  width: calc(100% - 70px);
`;

const NameText = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
  color: ${colors.white};
  white-space: nowrap;
  overflow-x: clip;
  width: min(100%, 100vw - 230px);
`;

const OnlineRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const OnlineIndicator = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: ${colors.green};
`

const BetaBadge = styled.div`
  background-color: ${colors.darkBrandBlue};
  color: ${colors.white};
  
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  padding: 0 15px;
  border-radius: 26px;
  margin-left: max(2px, min(17px, 100vw - 412px));
  max-height: 20px;
`;

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const NameRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: min(251px, 100vw - 165px);
`;

const OnlineText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 27px;
  color: ${colors.transparentWhite};
  padding-left: 9px;
  
  position: relative;
  top: -9px;
`;

export const Header = () => {
  return <HeaderContainer>
    <HeaderAgentIcon />
    <TextContainer>
      <TopRow>
        <NameRow>
          <NameText>Chat with Aurora Borea</NameText>
          <BetaBadge>
            BETA
          </BetaBadge>
        </NameRow>
        <WindowSizeButton />
      </TopRow>
      <OnlineRow>
        <OnlineIndicator />
        <OnlineText>Online</OnlineText>
      </OnlineRow>
    </TextContainer>
  </HeaderContainer>;
}
