import { createContext } from "react";

export type FeedbackContext = {
  isFeedbackActive: boolean;
  setIsFeedbackActive: (arg0: boolean) => void;
  setStars: (arg0: number) => void;
  stars: number;
  publishFeedback: (arg0: string) => void;
  isSent: boolean;
};

export const feedbackContext = createContext<FeedbackContext>({
  isFeedbackActive: false,
  setIsFeedbackActive: (arg0) => {},
  setStars: (arg0) => {},
  stars: 0,
  publishFeedback: (arg0) => {},
  isSent: false,
})
