import AbstractCache from "./abstractCache";
import { Message, Session } from "../types";

export default class LocalStorageCache extends AbstractCache {

  get(key: string) {
    return localStorage.getItem(key);
  }

  set(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  getMessages(): Promise<Message[] | undefined> {
    const messages = JSON.parse(this.get('messages') || "[]") as Message[]
    return Promise.resolve(messages);
  }

  getSession(): Promise<Session | undefined> {
    const sessionStr = this.get('session');
    const session = sessionStr ? JSON.parse(sessionStr) as Session : undefined
    return Promise.resolve(session);
  }

  setMessages(messages: Message[]): Promise<void> {
    this.set('messages', JSON.stringify(messages));
    return Promise.resolve();
  }

  setSession(session: Session): Promise<void> {
    this.set('session', JSON.stringify(session));
    return Promise.resolve();
  }

}