import { safeLocalStorage } from "../../uitils/safeLocalStorage";

export class TtlChecker {
  ttlSeconds: number;

  constructor(ttlSeconds: number) {
    this.ttlSeconds = ttlSeconds
  }

  getTimestamp(): number {
    return JSON.parse(this.get('ttl') || '0');
  }

  setTimestamp() {
    this.set('ttl', JSON.stringify(Date.now() / 1000))
  }

  clearCacheWhenTTLExpired() {
    const oldTimestamp = this.getTimestamp();
    const now = Date.now() / 1000;
    if (oldTimestamp + this.ttlSeconds < now) {
      safeLocalStorage.clear();
    }
  }

  get(key: string) {
    return safeLocalStorage.getItem(key);
  }

  set(key: string, value: string) {
    safeLocalStorage.setItem(key, value);
  }
}