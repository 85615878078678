import { env } from "../utils/env";

export async function recognition(blob: Blob) {
  const formData = new FormData();
  formData.append('file', blob);
  // formData.append('data', blob);
  const response = await fetch(env.url + '/transcribe', {
    method: 'POST',
    body: formData
  })
  const reply  = await response.json();
  return reply.text;
}