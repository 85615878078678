import LocalStorageCache from "./localStorageCache";
import { TtlChecker } from "../../../utils/ttlChecker";
import { Message, Session } from "../types";

export default class LocalStorageWithTTLCache extends LocalStorageCache {
  ttlChecker: TtlChecker;
  constructor(ttlSeconds: number) {
    super();
    this.ttlChecker = new TtlChecker(ttlSeconds);
  }

  getMessages(): Promise<Message[] | undefined> {
    this.ttlChecker.clearCacheWhenTTLExpired();
    return super.getMessages();
  }

  setMessages(messages: Message[]): Promise<void> {
    this.ttlChecker.setTimestamp();
    return super.setMessages(messages);
  }

  getSession(): Promise<Session | undefined> {
    this.ttlChecker.clearCacheWhenTTLExpired()
    return super.getSession();
  }

  setSession(session: Session): Promise<void> {
    this.ttlChecker.setTimestamp();
    return super.setSession(session);
  }
}