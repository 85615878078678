import { useRef, useEffect, useCallback, RefObject, useState } from "react";
import { useChat } from "../../../hooks/chatting/useChat";
import styled from "styled-components";
import { colors } from "../../../uitils/colors";
import { useFeedback } from "../../../hooks/feedback/useFeedback";
import { Buttons } from "./Buttons";

const InputContainer = styled.div`
  width: 100%;
  height: 85px;
  
  background: ${colors.white};
  border-radius: 0 0 8px 8px;
  cursor: text;
`;

const InputActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  margin: 15px 30px;
  width: calc(100% - 60px);
  height: calc(100% - 30px);
`;

const Input = styled.textarea`
  cursor: auto;
  height: 100%;
  width: 100%;
  
  border: none;
  font-family: "Proxima Nova", source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: ${colors.black};
  resize: none;
  
  &::placeholder {
    color: ${colors.grey};
  }
  
  &:focus {
    outline: none;
  }
  
  &::-webkit-scrollbar {
    width: 6px;
    background-color: ${colors.scrollWhite};
    border: 1px solid ${colors.backgroundGrey};
    border-radius: 3px;
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: ${colors.scrollGrey}; 
    transition: 3s;
    &:hover {
      background-color: ${colors.lightGrey};
    }
  }
`

export const ChatInput = () => {
  const {
    publishMessage,
    messages,
  } = useChat();
  const isFeedbackActiveRef = useRef<boolean>(false);
  const { isFeedbackActive, publishFeedback } = useFeedback();
  isFeedbackActiveRef.current = isFeedbackActive;
  const messagesRef = useRef(messages);
  messagesRef.current = messages;
  const [currentText, setCurrentText] = useState('');

  const ref = useRef<HTMLTextAreaElement>(null);
  const send = useCallback(() => {
    if (
      !ref.current || !ref.current.value.trim() ||
      messagesRef.current.length === 0 || (
        messagesRef.current[messagesRef.current.length - 1].chatRole === 'user' && !isFeedbackActiveRef.current
      )
    ) {
      return;
    }
    if (isFeedbackActiveRef.current) {
      publishFeedback(ref.current.value);
    } else {
      publishMessage(ref.current.value);
    }
    setCurrentText('');
    ref.current.value = '';
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    ref.current.addEventListener("keypress", function(event) {
      if (event.key === "Enter") {
        event.preventDefault();
        send();
      }
    });
  }, [send, ref]);

  let placeholder = "Leave your text or voice message here";
  if (isFeedbackActive) {
    placeholder = "Leave your text or voice feedback here";
  }

  return <InputContainer onClick={() => ref.current?.focus()}>
    <InputActionContainer>
      <Input
        ref={ref as RefObject<any>}
        placeholder={placeholder}
        onInput={() => setCurrentText(ref.current?.value || '')}
      />
      <Buttons setCurrentText={(str) => {
        if (!ref.current) {
          return
        }
        setCurrentText(str);
        ref.current.value = str;
      }} currentText={currentText} send={send} />
    </InputActionContainer>
  </InputContainer>
}
