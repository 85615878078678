import { Message } from "../../../../hooks/chatting/backend/types";
import styled from "styled-components";
import { colors } from "../../../../uitils/colors";
import { ReactComponent as ArrivedLogoImage } from "../../../../assets/arrivedLogo.svg";
import { Time } from "./Time";
import { useRef } from "react";

type Props = {
  message: Message;
}

const Container = styled.div`
  width: 100%;
  
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 25px;
`;

const ArrivedContainer = styled.div`
  fill: ${colors.blue};
  margin-right: 20px;
  margin-left: 3px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const MessageContainer = styled.div`
  border-radius: 8px;
  background-color: ${colors.blue};
  margin-left: 10px;
`;

const MessageContentContainer = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: ${colors.white};
  padding: 20px 25px 22px;
`;

const TimeContainer = styled.div`
  padding-left: 25px;
`

export const UserMessage = ({ message }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null); // FIXME: this a crutch
  const date = new Date(message.createdAt);

  let className = 'animate__animated ';
  if (message.state === 'unseen' && !ref.current) { // FIXME: this a crutch
    // FIXME: i don't know why, but "seen" functionality is lagging
    className += 'animate__slideInRight';
  }

  return <Container className={className} ref={ref}>
    <ArrivedContainer>
      <ArrivedLogoImage />
    </ArrivedContainer>
    <MessageContainer>
      <MessageContentContainer>
        { message.chatContent }
      </MessageContentContainer>
    </MessageContainer>
    <TimeContainer>
      <Time time={date} />
    </TimeContainer>
  </Container>
}
