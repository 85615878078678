import { useEffect } from "react";
import { Messages } from "./Messages";
import { ChatInput } from "./ChatInput";
import { useOpenClosed } from "../../hooks/openClosedManager/useOpenClosed";
import { useChat } from "../../hooks/chatting/useChat";
import { useNotification } from "../../hooks/notification/useNotification";
import styled from "styled-components";
import { useIframeVisibility } from "../../hooks/openClosedManager/useIframeVisibility";
import { Header } from "./Header";
import { FeedbackStars } from "./Messages/FeedbackStars";
import { useWindowScale } from "../../hooks/windowScale/useWindowScale";

const ChatContainer = styled.div`
  --animate-duration: 0.25s;
  height: calc(100% - 50px);
  width: calc(100% - 50px);
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  box-shadow: 0 14px 20px rgba(81, 169, 225, 0.04), 
              0 6px 22px rgba(81, 169, 225, 0.02), 
              0 7px 12px rgba(81, 169, 225, 0.1);
  
  margin: 25px;
`;

export const Chat = () => {
  const { resizingStarted } = useWindowScale();
  const { isOpen } = useOpenClosed();
  const { setMessages } = useNotification();
  const {
    messages, updateMessagesState
  } = useChat();
  const setVisibility = useIframeVisibility('conversation');

  useEffect(() => {
      if (isOpen) {
        setTimeout(() => {
          updateMessagesState('seen');
        }, 500);
      }
    },
    // eslint-disable-next-line
    [isOpen, messages.length]
  );

  useEffect(() => {
    setVisibility(isOpen);
  },
    // eslint-disable-next-line
    [isOpen]
  );

  useEffect(() => {
    setTimeout(() => {
      let updatedMessages = [...messages];
      if (updatedMessages.length > 0) {
        updatedMessages[0] = {
          ...updatedMessages[0],
          chatContent: 'GPT Help is on the Way!\nLet\'s chat!😊',
        };
      }
      setMessages(updatedMessages.filter(m => (m.state === 'unseen' && m.chatRole !== 'user')));
      // FIXME: crutch. Propably implement "ping pong" between iframes.
      // FIXME: Only when everything loaded, send messages between iframes
    }, 200)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages
    .filter(m => (m.state === 'unseen' && m.chatRole !== 'user'))
    .map(m => m.createdAt).join('.')
  ]);

  let className = 'animate__animated ';
  if (isOpen && !resizingStarted) {
    className += 'animate__zoomIn';
  } else {
    className += 'animate__fadeOut';
  }

  return <ChatContainer className={className}>
    <Header />
    <Messages />
    <FeedbackStars />
    <ChatInput />
  </ChatContainer>;
};
