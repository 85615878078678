import { ReactComponent as SendLogo } from "../../../../assets/sendLogo.svg";
import styled from "styled-components";
import { colors } from "../../../../uitils/colors";
import { useDebounce } from "usehooks-ts";
import { useChat } from "../../../../hooks/chatting/useChat";
import { MicrophoneButton } from "./MicrophoneButton";


const ButtonContainer = styled.div`
  margin-left: 21px;
  display: flex;
  flex-direction: column;
`;

const SendLogoContainer = styled.div`
  --animate-duration: 0.25s;
  margin-top: 3px;
  fill: ${colors.blue};
  cursor: pointer;
 
  &:hover {
    fill: ${colors.darkBlue};
  }
  &.animate__fadeOut {
    cursor: text;
  }
  transition: 0.3s;
`;

type Props = {
  currentText: string;
  setCurrentText: (arg0: string) => void;
  send: () => void;
}
export const Buttons = ({
  currentText, send, setCurrentText
}: Props) => {
  const { messages } = useChat();
  const debaouncedCurrentText = useDebounce(currentText, 250);

  let className = 'animate__animated ';
  if (debaouncedCurrentText.length > 0 && messages.length > 0 && messages[messages.length - 1].chatRole !== 'user') {
    className += 'animate__fadeIn';
  } else {
    className += 'animate__fadeOut'
  }
  return <ButtonContainer>
    <SendLogoContainer onClick={send} className={className}>
      <SendLogo />
    </SendLogoContainer>
    <MicrophoneButton currentText={currentText} setCurrentText={setCurrentText} />
  </ButtonContainer>
}