import AbstractCache from "./abstractCache";
import { Message, Session } from "../types";

export default class RamCache extends AbstractCache {
  messages: undefined | Message[]
  session: undefined | Session;
  constructor() {
    super();
    this.messages = undefined;
    this.session = undefined;
  }

  getMessages(): Promise<Message[] | undefined> {
    return Promise.resolve(this.messages);
  }

  getSession(): Promise<Session | undefined> {
    return Promise.resolve(this.session);
  }

  setMessages(messages: Message[]): Promise<void> {
    this.messages = messages
    return Promise.resolve();
  }

  setSession(session: Session): Promise<void> {
    this.session = session;
    return Promise.resolve();
  }

}