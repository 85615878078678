import { Message as MessageType } from "../../../../hooks/chatting/backend/types";
import { AssistantMessage } from "./AssistantMessage";
import { UserMessage } from "./UserMessage";

type Props = {
  message: MessageType;
};

export const Message = ({ message }: Props) => {
  if (message.chatRole === 'assistant') {
    return <AssistantMessage message={message}/>;
  } else {
    return <UserMessage message={message}/>;
  }
}
