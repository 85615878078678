import { useEffect, useState } from "react";
import { colors } from "../../uitils/colors";
import styled from "styled-components";

type ContainerProps = {
  duration: number;
};

const Container = styled.div<ContainerProps>`
  --animate-duration: ${props => props.duration}s;
  position: absolute;
  right: -4px;
  top: 3px;
  margin: auto;
  
  background-color: ${colors.orange};
  width: 18px;
  height: 18px;
  border-radius: 100%;
  box-shadow: 0 2px 2px rgba(47, 122, 169, 0.25);
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  
  margin: auto;
  color: ${colors.white};
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 8px;
`;

type Props = {
  messageCount: number;
}

export const UnreadMessageCount = ({ messageCount }: Props) => {
  const [messageCountContainer, setMessageCountContainer] = useState({
    cachedMessageCount: 0,
    isVisible: false,
    wasRendered: false,
  });

  useEffect(() => {
    if (messageCount !== 0) {
      setMessageCountContainer({
        cachedMessageCount: messageCount,
        isVisible: true,
        wasRendered: true,
      })
    } else {
      setMessageCountContainer({
        ...messageCountContainer,
        isVisible: false,
      });
    }

  },
    // eslint-disable-next-line
    [messageCount]
  );

  if (!messageCountContainer.wasRendered) {
    return <></>;
  }

  let className = 'animate__animated '
  let duration = 0;

  if (messageCountContainer.isVisible) {
    duration = 2;
    className += 'animate__bounceIn';
  } else {
    duration = 0.5
    className += 'animate__zoomOut'
  }

  return <Container duration={duration} className={className}>
    <TextContainer>
      {messageCountContainer.cachedMessageCount}
    </TextContainer>
  </Container>
}
