import { JSX } from "react";
import styled from "styled-components";
import "animate.css";


const Container = styled.div`
  --animate-duration: 0.5s;
  position: absolute;
  width: 100%;
  height: 100%;
`;

type Props = {
  children: JSX.Element;
  show: boolean;
}

export const BubbleImageContainer = ({ children, show }: Props) => {

  let className = 'animate__animated ';
  if (show) {
    className +=  'animate__bounceIn' //'animate__fadeIn';
  } else {
    className += 'animate__zoomOut';
  }

  return <Container className={className}>
    {children}
  </Container>
}
