import styled from "styled-components";
import { cropByMaxWordFit } from "../../uitils/text";

type Props = {
  content: string;
}

const ContentBlockContainer = styled.div`
  word-break: break-word;
  min-height: 8px;
`;
export const Content = ({content}: Props) => {
  const toRender = cropByMaxWordFit(content, 400);
  return <>
    {
      toRender
        .split('\n')
        .map((t, i) => <ContentBlockContainer key={i}>{t}</ContentBlockContainer>)
    }
  </>;
}
