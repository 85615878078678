import { Message } from "../chatting/backend/types";
import { createContext } from "react";

export type NotificationContext = {
  messages: Message[];
  setMessages: (arg0: Message[]) => void
}

export const notificationContext = createContext<NotificationContext>({
  messages: [],
  setMessages: (arg0) => {},
});