import { useOpenClosed } from "../../hooks/openClosedManager/useOpenClosed";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { useIframeMessaging } from "../../hooks/iframeMessaging/useIframeMessaging";
import { sleep } from "../../uitils/time";
import Agent from "../../assets/agent.png";
import { colors } from "../../uitils/colors";
import { ReactComponent as CloseButtonSvg } from "../../assets/closeButton.svg";


const Container = styled.div`
  --animate-duration: 0.5s;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentContainer = styled.div`
  --animate-duration: 0.5s;
  height: auto;
  width: min(calc(100vw - 40px), 700px);
  border-radius: 8px;
  border-width: 2px;
  border-color: ${colors.white};
  border-style: solid;
  background: url(${Agent}), lightgray -39.352px 0px / 117.243% 100% no-repeat;
  background-size: cover;
  /* 8px | 20% */
  box-shadow: 0 4px 5px 0 rgba(81, 169, 225, 0.20), 
              0 3px 14px 0 rgba(81, 169, 225, 0.12),
              0 8px 10px 0 rgba(81, 169, 225, 0.14);
  
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const CloseButton = styled.div`
  stroke: ${colors.white};
  transition: 0.5s;
  &:hover {
    stroke ${colors.lightGrey};
  }
  margin-top: 21px;
  margin-right: 30px;
`;

const PopupMessage = styled.div`
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.86);
  width: min(calc(100vw - 104px), 462px);
  height: auto;
  margin-top: 29px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const PopupMessageTitle = styled.div`
  color: ${colors.grey};
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 200% */
  margin-top: 23px;
  margin-left: 25px;
`;
const PopupMessageText = styled.div`
  overflow: clip;
  margin: 10px 25px 26px;
  color: ${colors.black};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 168.75% */
`;

const ButtonsRow = styled.div`
  margin: 50px 30px 30px;
  display: flex;
`;

const CancelButton = styled.div`
  display: inline-flex;
  height: 47px;
  padding: 0 22px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid ${colors.white};
  color: ${colors.white};
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 105.556% */
  transition: 0.5s;
  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
`;

const OpenChatButton = styled.div`
  display: inline-flex;
  height: 47px;
  padding: 0 22px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 4px;
  background: ${colors.blue};
  margin-left: 30px;;

  color: ${colors.white};
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 105.556% */
  transition: 0.5s;
  &:hover {
    background: ${colors.lightBlue};
  }
`;

async function showEnterAnimationPipeline(setStage: (arg0: number) => void) {
  await sleep(100);
  setStage(1)
  await sleep(500);
  setStage(2);
  await sleep(500);
}

async function showExitAnimationPipeline(setStage: (arg0: number) => void) {
  setStage(3);
  await sleep(500);
}

const PopupBubbleImplementation = () => {
  const isRunning = useRef(false);
  const { setIsOpen } = useOpenClosed();
  const [stage, setStage] = useState(0);
  useEffect(() => {
    if (isRunning.current) {
      return;
    }
    isRunning.current = true;
    showEnterAnimationPipeline(setStage).then(() => {
      setStage(-1);
      isRunning.current = false;
    });
  }, []);

  let classNameContainer = 'animate__animated ';
  let classNameContent = 'animate__animated ';
  if (stage === 0) {
    classNameContainer = 'notDisplay';
    classNameContent = 'notDisplay';
  } else if (stage === 1) {
    classNameContainer += 'animate__fadeIn';
    classNameContent += 'notDisplay';
  } else if (stage === 2) {
    classNameContent += 'animate__zoomIn';
  } else if (stage === 3) {
    classNameContainer += 'animate__fadeOut';
    classNameContent += 'animate__fadeOut';
  }

  const safeSetIsOpen = (isOpen: boolean) => {
    if (stage !== -1 || isRunning.current) {
      return;
    }
    isRunning.current = true;
    showExitAnimationPipeline(setStage).then(() => {
      setIsOpen(isOpen);
      isRunning.current = false;
    });
  }

  return <div className={classNameContainer}>
    <Container onClick={() => safeSetIsOpen(false)}>
      <div className={classNameContent}>
        <ContentContainer onClick={(e) => e.stopPropagation()}>
          <CloseButton onClick={(e) => {
            e.stopPropagation();
            safeSetIsOpen(false);
          }}>
            <CloseButtonSvg />
          </CloseButton>
          <PopupMessage>
            <PopupMessageTitle>Aurora Borea</PopupMessageTitle>
            <PopupMessageText>
              Hi, I'm Aurora Borea, InData Labs virtual assistant.
              I was created based on the GPT-4 model, trained on proprietary data of InData Labs.
              I am here to assist you in quick learning about the company’s services. Let's chat!
            </PopupMessageText>
          </PopupMessage>
          <ButtonsRow>
            <CancelButton onClick={() => safeSetIsOpen(false)} >Cancel</CancelButton>
            <OpenChatButton onClick={() => safeSetIsOpen(true)}>Open chat</OpenChatButton>
          </ButtonsRow>
        </ContentContainer>
      </div>
    </Container>
  </div>
}

export const PopupBubble = () => {
  const {
    onMessage, publishMessage, removeHandler
  } = useIframeMessaging();
  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {

    onMessage('popupBubble', 'setPopup', () => {
      setIsInitialized(true);
    });
    publishMessage('setPopup', { isPopup: true });

    return () => {
      removeHandler('popupBubble', 'setPopup');
      publishMessage('setPopup', { isPopup: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!isInitialized) {
    return <></>;
  }
  return <PopupBubbleImplementation />;
}
