import { useOpenClosed } from "../../hooks/openClosedManager/useOpenClosed";
import { useNotification } from "../../hooks/notification/useNotification";
import { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { useIframeVisibility } from "../../hooks/openClosedManager/useIframeVisibility";
import { useIframeHeightAdjust } from "../../hooks/openClosedManager/useIframeHeightAdjust";
import { MessageContainer } from "./MessageContainer";
import styled from "styled-components";
import { AgentIcon } from "./AgentIcon";
import { Message } from "../../hooks/chatting/backend/types";
import { roleMap } from "../../uitils/roleConversation";
import { useLocation } from "react-router-dom";

const Container = styled.div`
  --animate-duration: 0.5s;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 15px 35px 15px 15px;
`;

const IconShadowWrapper = styled.div`
  margin-right: 14px;
  border-radius: 100%;
  box-shadow: 
          0 2px 4px rgba(81, 169, 225, 0.04), 
          0 4px 5px rgba(81, 169, 225, 0.02), 
          0 1px 10px rgba(81, 169, 225, 0.1);
`;

const MessageShadowWrapper = styled.div`
  flex-basis: calc(100% - 50px);
  box-shadow: 0 2px 4px rgba(81, 169, 225, 0.14), 
              0 4px 5px rgba(81, 169, 225, 0.12), 
              0 1px 10px rgba(81, 169, 225, 0.2);
  border-radius: 8px;
`;

function useIsPopup() {
  const { search } = useLocation();

  const query = useMemo(() => new URLSearchParams(search), [search]);
  return query.get('popup') === 'true';
}

export const Notification = () => {
  const containerRef = useRef<HTMLDivElement | null>();
  const [
    messageContainer, setMessageContainer
  ] = useState({
    lastMessage: undefined as undefined | Message,
  });
  const [closed, setClosed] = useState(false);
  const { isOpen, setIsOpen, wasEverOpen } = useOpenClosed();
  const isPopup = useIsPopup();
  const { messages } = useNotification();
  const setVisibility = useIframeVisibility('notification');
  const adjustHeight = useIframeHeightAdjust('notification');

  const isVisible = !isOpen && messages.length > 0 && !closed && !(isPopup && !wasEverOpen);

  useEffect(() => {
    const isClosedFromLS = Boolean(localStorage.getItem('popupClosed'))
    setClosed(false || isClosedFromLS);
  }, [messages.length]);

  useEffect(() => {
    const isClosedFromLS = Boolean(localStorage.getItem('popupClosed'))
    if (isClosedFromLS !== closed) {
      setClosed(isClosedFromLS)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isVisible) {  
      setVisibility(isVisible);
      adjustHeight('100hv');
      setMessageContainer({
        lastMessage: messages[messages.length - 1]
      });
    } else if (messageContainer.lastMessage) {
      setTimeout(() => {
        setVisibility(isVisible);
        setMessageContainer({
          lastMessage: undefined,
        });
        adjustHeight('0px');
      }, 500);
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isVisible]);

  useEffect(() => {
    if (messageContainer.lastMessage) {
      adjustHeight((containerRef.current?.scrollHeight || 0).toString() + 'px');
    } else {
      adjustHeight('0px');
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [messageContainer]
  );

  if (!messageContainer.lastMessage) {
    return <div />;
  }

  let className ='animate__animated ';
  if (isVisible) {
    className += 'animate__slideInRight';
  } else if (isOpen) {
    className += 'animate__zoomOut';
  } else if (closed) {
    className += 'animate__slideOutRight';
  }

  return <Container
    ref={containerRef as RefObject<any>}
    className={className}
    onClick={() => {
      if (isVisible) {
        setIsOpen(true)
      }
    }}
  >
    <IconShadowWrapper>
      <AgentIcon />
    </IconShadowWrapper>
    <MessageShadowWrapper>
      <MessageContainer
        who={roleMap[messageContainer.lastMessage.chatRole]}
        content={messageContainer.lastMessage.chatContent}
        onClose={(e) => {
          e.stopPropagation()
          setClosed(true)
          localStorage.setItem('popupClosed', 'true')
        }}
      />
    </MessageShadowWrapper>
  </Container>
}
