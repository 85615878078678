import { JSX, useEffect, useRef } from 'react';
import { iframeMessagingContext } from "./iframeMessagingContext";

type Props = {
  children: JSX.Element,
}

export const IframeMessagingProvider = ({ children }: Props): JSX.Element => {
  const callbackTypeMapping = useRef<Record<string, string[]>>({})
  const callbacks = useRef<Record<string, (e: any) => void>>({});

  useEffect(() => {

    const handleMessage = (e: MessageEvent) => {

      const { type, data } = e.data as { type: string; data: any; };
      callbackTypeMapping.current[type]?.forEach((id) => {
        callbacks.current[id](data);
      });
    }

    window.addEventListener('message', handleMessage, false);
    return () => {
      window.removeEventListener('message', handleMessage);
    }
  }, []);


  return <iframeMessagingContext.Provider value={{
    onMessage: (id: string, type: string, callback: (e: any) => void) => {
      if (!callbackTypeMapping.current[type]) {
        callbackTypeMapping.current[type] = [];
      }
      callbackTypeMapping.current[type].push(id);
      callbacks.current[id] = callback;
    },

    publishMessage: (type: string, data: any) => {
      window.parent.postMessage({ type, data }, '*');
    },

    removeHandler: (id: string, type: string) => {
      const callbackMappings = callbackTypeMapping.current[type] || [];
      callbackMappings.forEach((currentId) => {
        if (currentId === id) {
          delete callbacks.current[id];
        }
      });

      callbackTypeMapping.current[type] = callbackMappings.filter(currentId => currentId !== id);
    }
  }}>
    { children }
  </iframeMessagingContext.Provider>
}