import AbstractChattingBackend from "./abstractChattingBackend";
import { Message, UserMessage, MessageState, Session, BotMessage } from "./types";
import AbstractCache from "./cache/abstractCache";
import { sleep } from "../../../uitils/time";

export default class HttpChatBackend extends AbstractChattingBackend {
  urlPrefix: string;
  cache: AbstractCache;
  timeout: any | undefined
  messageCallback: (arg0: Message[]) => void;
  chatSessionCallback: (arg0: string) => void;
  busy: boolean;

  constructor(cache: AbstractCache, urlPrefix: string) {
    super();
    this.cache = cache
    this.urlPrefix = urlPrefix;
    this.messageCallback = _ => {};
    this.chatSessionCallback = _ => {};
    this.busy = false;
  }

  onChatSessionUid(callback: (arg0: string) => void) {
    this.chatSessionCallback = callback;
  }

  onMessages(callback: (arg0: Message[]) => void): void {
    this.messageCallback = callback;
  }

  async _publishUserMessage(text: string) {
    const session = await this.getOrInitSession();
    const userMessage: UserMessage = {
      chatSessionUid: session.chatSessionUid,
      text
    }
    let allMessages = (await this.cache.getMessages()) || [];
    allMessages = [...allMessages, {
      chatContent: text,
      chatRole: 'user',
      createdAt: (new Date()).toString(),
      state: 'unseen',
      isEndOfDialog: false,
    }]
    await this.cache.setMessages(allMessages);
    this.messageCallback(allMessages)
    let newMessage: BotMessage = {
      chatContent:
        "Aurora is very busy right now and not responding. " +
        "Please use [our contact form](https://indatalabs.com/contacts) " +
        "and our manager will contact you as soon as possible.",
      chatRole: 'assistant',
      createdAt: (new Date()).toString(),
      isEndOfDialog: false
    }
    let solved = false;

    const processMessage = async () => {
      try {
        const response = await fetch(`${this.urlPrefix}/process-user-message`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(userMessage)
        });
        newMessage = await response.json();
      } catch (e) { // cannot increase
        let messages: Message[] = [];
        while (messages.length <= allMessages.length) {
          if (solved) {
            return;
          }
          await sleep(5000);
          messages = await this.fetchMessages(session);
        }
        newMessage = messages[messages.length - 1]
      }
      newMessage.createdAt += 'Z';
    }

    await Promise.any([processMessage(), sleep(1000 * 4 * 60)])
    solved = true;

    allMessages = [ ...allMessages, { ...newMessage, state: 'unseen' }];
    await this.cache.setMessages(allMessages);
    this.messageCallback(allMessages);
  }

  publishUserMessage(text: string): void {
    if (this.busy) {
      return;
    }
    this.busy = true;
    this._publishUserMessage(text).then(() => { this.busy = false; });
  }

  async fetchMessages(session: Session): Promise<Message[]> {
    const response = await fetch(`${this.urlPrefix}/chat-history/${session.chatSessionUid}`);
    const messages: BotMessage[] = (await response.json()).messages;

    return messages.map((msg) => ({ ...msg, state: 'unseen' }));
  }

  async getOrInitSession(): Promise<Session> {
    let session = await this.cache.getSession();
    if (!session) {
      while (true) {
        try {
          const response = await fetch(`${this.urlPrefix}/new-chat-session`, {method: 'POST'});
          session = await response?.json() as Session;
          break;
        } catch (e) {
          console.log(e)
          await sleep(500);
        }
      }
      await this.cache.setSession(session);
      this.chatSessionCallback(session.chatSessionUid);
    }

    return session;
  }

  async fetchHelloMessage(): Promise<Message> {
    // const response = await fetch(`${this.urlPrefix}/request-introduction`);
    // const message: BotMessage = await response.json();
    const message: BotMessage = {
      chatContent: "Hi, I'm Aurora Borea, InData Labs virtual assistant. " +
        "Don't hesitate to ask me any questions about your AI needs. Please introduce " +
        "yourself and your company. " +
        "If you share your company's website, I can better assist with your request",
      chatRole: "assistant",
      createdAt: (new Date()).toString(),
      isEndOfDialog: false
    }
    return { ...message, state: 'unseen' };
  }

  async startNewChat() {
    const helloMsg = await this.fetchHelloMessage();
    await this.cache.setMessages([helloMsg]);
    this.messageCallback([helloMsg]);
  }

  async continueGuestChat(messages: Message[]) {
    this.messageCallback(messages);
  }

  async continueExistingChat(session: Session) {
    this.timeout = undefined;
    let messages = await this.cache.getMessages();
    if (!messages) {
      messages = [];
    }
    this.messageCallback(messages);
    // check for new messages
    const newMessages = await this.fetchMessages(session);
    if (messages.length < newMessages.length) {
      messages = [...messages, ...newMessages.slice(messages.length)];
      await this.cache.setMessages(messages);
      this.messageCallback(messages);
    }

    if (messages[messages.length - 1].chatRole === 'user') {
      this.timeout = setTimeout(() => {
        this.continueExistingChat(session);
      }, 5 * 1000) // 5s
    }
  }

  async _start() {
    const session = await this.cache.getSession();
    const messages = await this.cache.getMessages();
    if (!session) {
      if (!messages || !messages.length) {
        await this.startNewChat();
      } else {
        await this.continueGuestChat(messages);
      }
    } else {
      this.chatSessionCallback(session.chatSessionUid);
      await this.continueExistingChat(session);
    }
  }

  start(): void {
    if (this.busy) {
      return;
    }
    this.busy = true;
    this._start().then(() => { this.busy = false; });
  }

  stop(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
  }

  async _updateMessageState(newState: MessageState) {
    const messages = await this.cache.getMessages();
    if (!messages) {
      return;
    }
    const newMessages = messages.map((msg) => ({
      ...msg, state: newState
    }));
    await this.cache.setMessages(newMessages);
    this.messageCallback(newMessages);
  }
  updateMessagesState(newState: MessageState): void {
    this._updateMessageState(newState).catch(e => console.error(`update message state failed with ${e}`));
  }
}