export function cropByMaxWordFit(text: string, maxLength: number): string {
  const maxL = maxLength - 3;
  if (text.length <= maxLength) {
    return text;
  }

  let accumulator = '';
  let accumulatorBuffer = '';
  const tokens = text.split(' ').reverse();
  while (tokens.length > 0) {
    const token = tokens.pop();
    if (!token) {
      return accumulator;
    }
    if (accumulator) {
      accumulator = accumulator + ' ';
    }
    accumulator = accumulator + token;

    if (accumulator.length > maxL) {
      return accumulatorBuffer + '...';
    }
    accumulatorBuffer = accumulator;
  }
  return accumulator;
}
