import { JSX, useEffect, useState, useRef } from "react";
import { openClosedContext } from "./openClosedContext";
import { TtlChecker } from "../utils/ttlChecker";
import { useIframeMessaging } from "../iframeMessaging/useIframeMessaging";

type Props = {
  children: JSX.Element;
  ttlSeconds: number;
}

export const OpenClosedProvider = ({ ttlSeconds, children }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [wasEverOpen, setWasEverOpen] = useState(false);
  const ttlCheckerRef = useRef<TtlChecker | undefined>();
  const { onMessage, publishMessage, removeHandler } = useIframeMessaging();

  useEffect(() => {
    ttlCheckerRef.current = new TtlChecker(ttlSeconds);
    ttlCheckerRef.current?.clearCacheWhenTTLExpired();
    const isOpenValue: boolean = JSON.parse(ttlCheckerRef.current?.get('isOpen') || 'false');
    setIsOpen(isOpenValue);
    setWasEverOpen(ttlCheckerRef.current?.get('isOpen') !== null);

    onMessage('open-closed', 'newIsOpen', ({ isOpen }: { isOpen: boolean }) => {
      setIsOpen(isOpen);
      setWasEverOpen(true);
    })

    return () => {
      removeHandler('open-closed', 'newIsOpen')
    }
  }, [ttlSeconds, onMessage, removeHandler]);

  return <openClosedContext.Provider value={{
    isOpen,
    setIsOpen: (newIsOpen) => {
      setIsOpen(newIsOpen);
      setWasEverOpen(true);
      ttlCheckerRef.current?.setTimestamp();
      ttlCheckerRef.current?.set('isOpen', JSON.stringify(newIsOpen));
      publishMessage('newIsOpen', { isOpen: newIsOpen });
    },
    wasEverOpen,
  }}>
    { children }
  </openClosedContext.Provider>
}