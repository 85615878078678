import { useEffect, useState, JSX, useRef } from 'react';
import { windowScaleContext } from "./windowScaleContext";
import { useIframeMessaging } from "../iframeMessaging/useIframeMessaging";
import { sleep } from "../../uitils/time";
import { safeLocalStorage } from "../../uitils/safeLocalStorage";

type Props = {
  children: JSX.Element;
};
export const WindowScaleProvider = ({ children }: Props) => {
  const { publishMessage } = useIframeMessaging();
  const startedRef = useRef(false);
  const [resizingStarted, setResizingStarted] = useState(false);
  const [resizingFinished, setResizingFinished] = useState(false);
  const [isResized, setIsResizedInner] = useState(
    JSON.parse(safeLocalStorage.getItem('isResized') || 'false')
  );

  const setIsResized = async (arg: boolean) => {
    if (startedRef.current) {
      return;
    }
    startedRef.current = true;
    setResizingStarted(true);
    await sleep(250);
    setIsResizedInner(arg);
    safeLocalStorage.setItem('isResized', JSON.stringify(arg));
    publishMessage('resize', { isResized: arg, force: false });
    await sleep(100);
    setResizingFinished(true);
    setResizingStarted(false)
    await sleep(250);
    setResizingFinished(false);
    startedRef.current = false;
  }


  useEffect(() => {
    publishMessage('resize', { isResized, force: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <windowScaleContext.Provider value={{
    isResized, resizingStarted, resizingFinished, setIsResized,
  }}>
    {children}
  </windowScaleContext.Provider>
}
