import 'animate.css';
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { PopupBubble } from "./PopupBubble";
import { SimpleBubble } from "./SimpleBubble";
import { useOpenClosed } from "../../hooks/openClosedManager/useOpenClosed";


function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}


export const Bubble = () => {
  const { wasEverOpen } = useOpenClosed();
  const query = useQuery();

  if (query.get('popup') === 'true' && !wasEverOpen) {
    return <PopupBubble />;
  }

  return <SimpleBubble />;
}
